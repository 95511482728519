.clientesButtonMobile {
    margin-bottom: 20px ! important;
}

.clientesButtonMobile li {
    background-color: rgb(185, 0, 0);
    color: white;
    font-weight: bold;
    padding: 12px;
    border-radius: 8px;
    align-items: center;
    width: min-content;
}

.clientesButtonMobile li:hover {
    background-color: rgb(185, 50, 50);
}

.activeNavLink {

    ul li {
        font-weight: 400;
    }

    .mainLi {

        font-weight: bolder;
    }

}

.activeSubNavLink {
    li {
        font-style: italic;
    }
}

.mobileMenu {
    z-index: 110;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 25px;
    margin-left: 25px;
    margin-top: 22px;

    .topLine,
    .middleLine,
    .bottomLine {
        width: 30px;
        height: 25px;
        background-color: rgb(185, 0, 0);
        transition: .5s;
        border-radius: 5px;
    }

    .topLineToDefault {
        animation: rotateTopToDefault .5s ease 1 both;
    }

    .middleLineToDefault {
        animation: rotateMiddleToDefault .5s ease 1 both;
    }

    .bottomLineToDefault {
        animation: rotateBottomLineToDefault .5s ease 1 both;
    }

}

.mobileMenuOpened {

    .topLine {
        animation: rotateTop .5s ease 1 both;
    }

    .middleLine {
        animation: rotateMiddle .5s ease 1 both;
    }

    .bottomLine {
        animation: eraseBottonLine .5s ease 1 both;
    }
}

@keyframes rotateTop {
    from {
        rotate: 0;
    }

    to {
        rotate: 45deg;
        translate: 0 11px;
    }
}

@keyframes rotateMiddle {
    from {
        rotate: 0;
    }

    to {
        rotate: -45deg;
    }
}

@keyframes eraseBottonLine {
    from {
        translate: 0 0;
    }

    to {
        translate: 0 -10px;
        rotate: 45deg;
    }
}

@keyframes rotateTopToDefault {
    from {
        rotate: 45deg;
        translate: 0 11px;
    }

    to {
        rotate: 0;
    }
}

@keyframes rotateMiddleToDefault {
    from {
        rotate: -45deg;
    }

    to {
        rotate: 0;

    }
}

@keyframes rotateBottomLineToDefault {
    from {
        translate: 0 -10px;
        rotate: 45deg;
    }

    to {
        translate: 0 0;
    }
}

.showMobileMenuContainer {
    animation: showMobileMenuContainer .5s ease 1 both ! important;
}

.mobileMenuContainer {
    display: none;
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    left: -115%;
    background-color: #ffffff;
    animation: notShowMobileMenuContainer .5s ease 1 both;
    display: flex;
    font-family: 'Montserrat', sans-serif;

    a {
        color: black;
        text-decoration: none;

    }

    .mainLi {
        font-size: calc(0.7em + 1.8vw);

    }

    ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        margin-top: 80px;
        margin-left: 50px;
        font-size: calc(0.7em + 1.6vw);

        li {
            margin-top: 10px;
        }

    }

    .dropdownContainer {
        margin: 5px 0 15px 20px;
    }
}

@keyframes notShowMobileMenuContainer {
    from {
        left: 0;
    }

    to {
        left: -115%;
    }
}

@keyframes showMobileMenuContainer {
    from {
        left: -115%;
    }

    to {
        left: 0;
    }
}