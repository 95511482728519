@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;700&family=Montserrat:wght@300;400;600&family=Open+Sans:wght@300;400;700&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

#root {
    overflow-x: hidden;

    position: relative;
    padding-bottom: 250px;
    background-color: rgb(243, 243, 243);
}

.buttonBisque {
    cursor: pointer;
    width: 100%;
    padding: 14px 20px;
    border-radius: 24px;
    border: 0;
    background-color: bisque;
    font-weight: bold;
}

.buttonRed {
    cursor: pointer;
    width: 100%;
    padding: 14px 20px;
    border-radius: 24px;
    border: 0;
    background-color: rgb(185, 0, 0);
    font-weight: bold;
    color: white;
}

@media screen and (max-width: 670px) {
    .segurosSectionContainer, .serviciosSectionContainer, .nosotrosBannerContainer, .contactSectionContainer, .novedadesSectionContainer {
        margin: auto ! important;
        margin-top: 90px ! important;

    }

}