.novedad-container {
    border-radius: 8px;
    background-color: rgb(245, 245, 245);
    border: 1px solid rgb(189, 189, 189);
    padding: 30px;
    margin: 0px;

    .titulo-novedad {
        text-align: left ! important;
        padding-bottom: 15px ! important;
        text-decoration: underline;
        color: #222222;
        font-size: 24px;
        font-style: normal ! important;
        font-weight: 600 ! important;
    }

    .descripcion-novedad {
        margin-top: 15px;
        font-size: 18px;
        text-align: start;
    }

    .fecha-container-novedad {
        font-size: 16px;
        margin-top: 15px;
        display: flex;
        gap: 10px;

        .fecha-novedad {
            font-weight: bolder;

        }
    }

    .footer-novedad {
        display: flex;
        justify-content: flex-start;

        img {
            width: 100px;
        }
    }
}

.imagenesNovedad {
    margin-top: 20px;
    flex-wrap: wrap;

    img {
        @media screen and (max-width: 768px) {
            margin-top: 10px;
        }
    }
}

.footer-novedad {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    justify-content: flex-start;
    gap: 25px;

    .footer-container {

        ul {
            justify-content: space-between;
            display: flex;
            list-style: none;

            li {
                cursor: pointer;

                i {
                    margin-right: 5px;
                    color: rgb(0, 0, 0);
                }

                a:hover i {
                    color: rgb(188, 0, 0);
                }

                &:hover {
                    color: rgb(188, 0, 0);
                }
            }
        }

        div:first-child {
            margin-top: 10px;
            margin-left: 10px;
            display: flex;
            justify-content: end;

            img {
                width: auto;
                height: 30px;
            }
        }

        p {
            font-style: italic;

            margin-top: -10px;
            text-align: center;
            grid-column: 1 / -1;
        }

        div:last-child {
            margin-right: 10px;
        }
    }
}