#root {
    min-height: 70vh;
}

.emailSentSectionContainer {
    margin: auto;
    margin: 0 10px;

    @media screen and (max-width: 768px){
        margin-top: 8rem;
    }

    .emailSentContainer {
        background-color: white;
        max-width: 700px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.422);
        margin: auto;
        margin-top: 25px;
        padding: 25px;

        //height: 500px;
        h1 {
            text-align: center;
            padding:  0 15px;
            padding-bottom: 15px;
        }

        p {
            text-align: center;
            font-size: 1.2em;
            margin: auto;
            padding: 0 25px;
        }

        button {
            cursor: pointer;
            margin-top: 20px;
            width: 100%;
            padding: 12px 18px;
            font-size: 1.3em;
            border-radius: 4px;
            border: 0;
            color: white;
            background-color: rgb(200, 0, 0);
            font-weight: bold;

            &:hover {
                background-color: rgb(185, 0, 0);
            }
        }
    }
}