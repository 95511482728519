@media screen and (min-width: 750px) {
    .floatingIcons {
        display: flex;
        justify-content: end;
        align-items: center;
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 100;
        gap: 10px;
        width: 300px;
        align-items: center;

        a {
            color: black;
            text-decoration: none;
        }

        .floatingWhatsappBackground,
        .floatingInstagramBackground {
            opacity: 0;
            width: 0;
            height: 40px;

            z-index: -2;
            translate: -5px 2px;
            padding-right: 5px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;

            p {
                margin-left: 10px;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .floatingInstagramLogo:hover {
            .floatingInstagramBackground {
                opacity: 1;
                width: 130px;
                background-color: rgb(255, 187, 187);
            }
        }

        .floatingWhatsappLogo:hover {
            .floatingWhatsappBackground {
                opacity: 1;
                width: 110px;
                background-color: rgb(182, 255, 164);

            }
        }


        div {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.5s;

        }

        img {
            margin-top: 5px;
            height: 50px;
        }
    }


}

@media screen and (max-width: 750px) {
    .floatingIcons {
        display: flex;
        justify-content: end;
        align-items: center;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 100;
        gap: 6px;
        width: 300px;
        align-items: center;

        a {
            color: black;
            text-decoration: none;
        }

        .floatingWhatsappBackground,
        .floatingInstagramBackground {
            opacity: 0;
            width: 0;
            height: 40px;

            z-index: -2;
            translate: -5px 2px;
            padding-right: 5px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;

            p {
                margin-left: 10px;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        div {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.5s;

        }

        img {
            overflow: hidden;
            margin-top: 5px;
            height: 40px;
        }
    }
}