.notFoundContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: auto;
    margin-top: 50px;
    h1{
        text-align: center;
    }
    button {
        cursor: pointer;
        margin-top: 20px;
        width: 100%;
        padding: 12px 18px;
        font-size: 1.3em;
        border-radius: 4px;
        border: 0;
        color: white;
        background-color: rgb(200, 0, 0);
        font-weight: bold;

        &:hover {
            background-color: rgb(185, 0, 0);
        }
    }
}