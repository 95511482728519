.serviciosSectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0 10px;
    max-width: 1100px;
    
    .serviciosContainer {
        margin-top: 25px;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.422);
        padding-bottom: 25px;
        h1 {
            text-align: center;
            padding-top: 20px;
        }

        h2 {
            text-align: center;
            font-style: italic;
            font-weight: 400;
            padding-bottom: 20px;
            border-bottom: 1px solid rgb(186, 186, 186);
            width: 90%;
        }

        .serviciosInfoContainer {
            width: 100%;
            padding: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .serviciosInfo {
                max-width: 800px;
                //width: calc(100% - 50px);
                display: flex;
                flex-direction: column;
                align-items: center;
                &:nth-child(2), &:nth-child(3){
                    margin-top: 50px;
                }
                img{
                    width: 350px;
                }
                div{
                    margin: 20px; 
                    p{
                        font-size: 1.1em;
                        margin: 0 30px;
                        margin-bottom: 8px;
                        //max-width: 720px;
                    }                  
                    h3{
                        text-align: center;
                        margin-bottom: 10px;
                        font-size: 1.3em;
                    }
                    h4{
                        text-align: center;
                        margin-bottom: 7px;
                        padding-top: 10px;
                    }
                    ul{
                        margin: 0 10% 0 15%;
                        li{
                            margin-bottom: 5px;
                        }
                    }
                    .servicioClarification{
                        margin-top: 15px;
                        font-style: italic;
                        
                    }
                }
            }
        }
    }

}