.topDataSection {
    width: 100%;
    background-color: rgb(185, 0, 0);
    display: flex;
    justify-content: center;
    padding: 5px 0 7px 0;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    z-index: 1000;

    a {
        display: flex;
        justify-content: center;
        color: white;
        text-decoration: none;
        i{
            margin-right: 5px;
        }
        p {
            margin-right: 10px;
        }
        &:hover{
            color: bisque;
        }
    }
}

.topDataSectionMobile{
    @media screen and (min-width: 990px){
        display: none;
    }
}

.topDataSectionDesktop{
    display: flex;
    @media screen and (max-width: 990px){
        display: none;
    }
}