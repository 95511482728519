.nosotrosSectionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;


    .nosotrosBannerContainer {
        width: 100%;
        height: 420px;
        overflow: hidden;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.431);
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &::after {
            position: absolute;
            width: 100%;
            top: -120%;
            left: -60%;

            content: url("../../../public/images/banner.jpg");
            filter: blur(7px) brightness(120%);
        }

        div {
            max-width: 800px;
            padding: 0 20px;

            img {
                position: relative;
                z-index: 2;
                height: auto;
                width: 100%;
                filter: drop-shadow(2px 2px 1px rgb(0, 0, 0));
            }
        }

        h1 {
            font-family: 'Times New Roman', Times, serif;
            position: relative;
            z-index: 2;
            font-size: calc(1.5em + 0.8vw);
            color: rgb(0, 0, 0);
            text-shadow: 1px 1px 3px rgb(255, 255, 255);
            font-weight: bold;
            margin: 0 20px;
            margin-top: 10px;
        }
    }

    .nosotrosContainer {
        margin: 0 10px;
        margin-top: 25px;
        max-width: 1100px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.242);
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h2 {
            padding-top: 20px;
        }

        h3 {
            font-style: italic;
            font-weight: 400;
            padding-bottom: 20px;
            border-bottom: 1px solid rgb(186, 186, 186);
            width: 90%;
        }

        .nosotrosInfoContainer {
            padding-bottom: 80px;
            width: 90%;

            p {
                padding-top: 20px;
                font-size: 1.5em;

                span {
                    font-style: italic;
                    font-weight: bold;
                }

                span:nth-child(3) {
                    font-style: normal;
                }
            }

            .middleNosotrosText {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 50px;
                
                padding-bottom: 20px;
                margin-bottom: 25px;
                border-bottom: 1px solid rgb(186, 186, 186);
                p {
                    font-weight: bold;
                    font-size: 1.1em;
                    margin-bottom: 0;
                    &:last-child{
                        font-weight: 400;
                        font-style: italic;
                        padding-top: 0;
                    }
                }
            }




            .nosotrosSegurosContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90%;
                flex-wrap: wrap;
                gap: 20px;
                margin: auto;
                margin-top: 20px;

                img {
                    width: 110px;

                }
            }

            .nosotrosServiciosContainer {
                display: flex;
                justify-content: space-around;
                align-content: center;
                flex-wrap: wrap;
                width: 90%;
                margin: auto;
                margin-top: 50px;
                gap: 30px;

                a {
                    min-width: 150px;
                    max-width: 250px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}