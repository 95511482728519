footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    bottom: 0;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    a{
        color: black;
        text-decoration: none;
    }
    background-color: rgb(255, 255, 255);
}

.footerContent {
    margin: 0 20px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 15px 0px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    justify-content: center;
    gap: 25px;

    ul {
        list-style: none;

        li {
            cursor: pointer;

            i {
                margin-right: 5px;
            }
            a:hover{
                color: rgb(188, 0, 0);
            }
            &:hover {
                color: rgb(188, 0, 0);
            }
        }
    }

    div:first-child {
        margin-top: 10px;
        margin-left: 10px;
        display: flex;
        justify-content: end;

        img {
            width: auto;
            height: 60px;
        }
    }

    p {
        font-style: italic;

        margin-top: -10px;
        text-align: center;
        grid-column: 1 / -1;
    }
    div:last-child{
        margin-right: 10px;
    }
}

.informacionRegulatoria {
    background-color: rgb(231, 231, 231);
    display: flex;
    justify-content: center;
    padding: 8px 5px;
    text-align: center;
    word-break: break-all;

}

.devCredits {
    background-color: rgb(185, 0, 0);
    display: flex;
    justify-content: center;
    padding: 8px 5px;

    p {
        color: white;
        font-size: 0.8em;
        font-weight: 100;
        text-align: center;

        span {
            font-style: italic;
            margin-right: 5px;
        }

        a {
            text-decoration: none;
            color: white;

            i {
                margin-right: 3px;
            }

            &:hover {
                color: bisque;
            }
        }
    }

    p:first-child {
        margin-right: 6px;
    }

    p:last-child {
        a {
            margin-right: 3px;
        }

    }

}
@media screen and (max-width: 556px){
    .footerContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        .footerContactInfo{
            display: flex;
            justify-content: center;
            ul{
                text-align: center;
                li{
                    margin-bottom: 8px;
                }
            }
        }
    }
    #root{
        padding-bottom: 400px ! important;
    }
}
@media screen and (max-width: 590px){

    #root{
        padding-bottom: 280px ! important;
    }
}
@media screen and (max-width: 556px){

    #root{
        padding-bottom: 400px ! important;
    }
}
@media screen and (max-width: 417px){

    #root{
        padding-bottom: 420px ! important;
    }
}
@media screen and (max-width: 361px){

    #root{
        padding-bottom: 420px ! important;
    }
}
@media screen and (max-width: 318px){

    #root{
        padding-bottom: 440px ! important;
    }
}
@media screen and (max-width: 312px) {
    #root{
        padding-bottom: 480px ! important;
    }
    
}