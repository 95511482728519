.clientesButtonRedirect li{
    background-color: rgb(185, 0, 0);
    color: white;
    font-weight: bold;
    margin: auto;
    border-radius: 8px;
    height: 60% ! important;
    margin: 11% 0;
    margin-left: 10px;
    align-items: center;
}
.clientesButtonRedirect li:hover{
    background-color: rgb(185, 50, 50);
}
.activeNavLink {

    li {
        font-weight: 400 ! important;
        font-style: italic ! important;
        font-weight: 600 ! important;
    }

}

nav {
    display: flex;
    justify-content: space-between;
    overflow: visible;
    background-color: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.139);
    padding: 0 5%;

    .navbarLogoContainer {

        margin-right: 20px;
        display: flex;
        align-items: center;

        img {
            padding: 5px 0;
            height: 40px;

        }
    }

    .navigationContainer {
        height: 65px;
        margin-right: 30px;
        position: relative;
        display: flex;
        overflow: visible;

        a {
            color: rgb(0, 0, 0);
            text-decoration: none;
        }

        li {
            z-index: 2;
            display: flex;
            align-items: center;
            height: 100%;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-size: 1em;
            cursor: pointer;
            padding: 0 15px;
        }

        .mainLiContainer {
            position: relative;

            ::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 0;
                background-color: #ededed;
                z-index: -1;
            }

            .mainLi {
                position: relative;
                overflow: visible;
            }

            .dropdownContainer {
                display: none;
                position: absolute;
                top: 100%;
                width: 200px;
                z-index: 100;
                box-shadow: 0 5px 7px rgba(0, 0, 0, 0.414);
            }

            &:hover {
                ::before {
                    animation: scrollDownBackground 0.3s ease both;
                }

                .dropdownContainer {
                    width: max-content;
                    translate: -15px 0;
                    color: rgb(0, 0, 0);
                    display: block;
                    animation: easeOpacity 0.3s ease both;

                    li {
                        font-size: 1em;
                        padding: 10px;
                        padding-left: 15px;

                        &:hover {
                            background-color: #f8f8f8;
                        }
                    }
                }
                .segurosDropdownContainer {
                    width: 150px;
                    translate: -10px 0;
                }

            }

            &:not(hover) {
                ::before {
                    animation: scrollUpBackground 0.3s ease both;
                }
            }


        }






    }
}

@keyframes easeOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes scrollUpBackground {
    from {
        height: 100%;
    }

    to {
        height: 0%;
    }

}

@keyframes scrollDownBackground {
    from {
        height: 0%;
    }

    to {
        height: 100%;
    }
}

@media screen and (max-width: 670px) {
    nav {
        height: 70px;
        justify-content: start;
        position: fixed;
        width: 100%;
        margin-top: 27px;
        z-index: 1000;
        top: 0;
        padding: 0 0;
    }
    .topDataSection{
        position: fixed;
    }
}

.navMobileWrapper{
    display: none;
    width: 100%;
    @media screen and (max-width: 670px){
        display: flex;
        justify-content: space-between;
        margin-right: 0.5rem;
    }

}

.navDesktopWrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 670px){
        display: none;
    }
}