.contactSectionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 10px;

    .contactContainer {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.421);
        min-width: 70vw;
        display: flex;
        justify-content: center;
        align-content: start;
        background-color: white;
        text-align: center;
        margin-top: 25px;
        padding: 25px ;
        padding-bottom: 50px;
        

        h1 {
            text-align: center;

        }

        h2 {
            font-weight: 400;
            font-style: italic;
            margin-bottom: 10px;
        }

        .formContactContainer {
            max-width: 500px;
            width: 80vw;


            .form {

                display: flex;
                flex-direction: column;


                label {
                    font-size: 1.1em;
                    margin-bottom: 3px;
                    text-align: start;
                }

                input {
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    padding: 5px 8px;
                    border: 0;
                    border-radius: 4px;
                    background-color: rgb(241, 241, 241);
                    font-size: 1em;
                    margin-bottom: 15px;
                }

                ::-webkit-inner-spin-button,
                input[type=number]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                textarea {
                    resize: none;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    padding: 5px 8px;
                    border: 0;
                    border-radius: 4px;
                    background-color: rgb(241, 241, 241);
                    font-size: 1em;
                    margin-bottom: 15px;
                    max-width: calc(100% - 15px);
                    min-width: calc(100% - 15px);
                    min-height: 150px;
                }

                button {
                    cursor: pointer;
                    width: 100%;
                    padding: 7px 12px;
                    border-radius: 4px;
                    font-size: 1.1em;
                    border: 0;
                    background-color: rgb(185, 0, 0);
                    font-weight: bold;
                    color: white;

                }
            }
        }
    }

}