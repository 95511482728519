.segurosSectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0 10px;
    max-width: 1100px;

    .segurosContainer {
        margin-top: 25px;
        padding: 20px 0;
        background-color: white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.429);

        .segurosTitle {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 45px;
            text-align: center;

            h2 {
                font-style: italic;
                font-weight: 400;
            }
        }

        .segurosGridContainer {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            align-items: start;


            .seguroContainer:nth-child(2) {
                border-left: 1px solid rgb(186, 186, 186);
                border-right: 1px solid rgb(186, 186, 186);

                @media screen and (max-width: 770px) {
                    border-right: 0;
                }

                @media screen and (max-width: 520px) {
                    border: 0;
                }
            }

            .seguroContainer:nth-child(3) {
                @media screen and (max-width: 770px) and (min-width: 520px) {
                    h3 {
                        padding-bottom: 10px;
                    }
                    grid-column: 1 / -1;
                }
            }

            .seguroContainer {

                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                padding: 0 5%;
                margin-bottom: 50px;

                .seguroTitle {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 55px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid rgb(186, 186, 186);

                    h3 {
                        font-weight: 400;
                    }
                }

                .seguroInfo {
                    margin-top: 10px;
                    font-size: 1.1em;
                    padding-bottom: 10px;

                    ul {

                        list-style: none;

                        li {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }

        .segurosContactButton {
            width: 95%;
            margin: auto;

            button {
                cursor: pointer;
                width: 100%;
                padding: 15px 20px;
                font-size: 1.3em;
                border-radius: 4px;
                border: 0;
                color: white;
                background-color: rgb(200, 0, 0);
                font-weight: bold;

                &:hover {
                    background-color: rgb(185, 0, 0);
                }
            }
        }

    }
}