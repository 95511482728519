.expandedImageWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow: hidden;
}
.expandedImageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.novedades-container{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 25px;
}
.novedadesSectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0 10px;
    max-width: 1100px;

    .novedadesContainer {
        box-sizing: border-box;
        margin-top: 25px;
        padding: 0px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.422);
        padding-bottom: 35px;

        h1 {
            text-align: center;
            padding-top: 20px;
        }

        h2 {
            text-align: center;
            font-style: italic;
            font-weight: 400;
            padding-bottom: 20px;
            border-bottom: 1px solid rgb(186, 186, 186);
            width: 90%;
        }
    }
}